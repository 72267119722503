
.card-margine{
    color: #ffffff;
    background: linear-gradient(to bottom right, #a3a8f0, #cabefa);
    box-shadow: 0 0 2px 1px rgb(40 40 40 / 15%);
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.card-centru{
    color: #ffffff;
    background: linear-gradient(to bottom right, #a3a8f0, #696fdd);
    box-shadow: 0 0 2px 1px rgb(40 40 40 / 15%);
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.css-1utwwx4{
    appearance: none;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    margin: 0px;
    padding: 0px;
    border: none;
    background: transparent;
    cursor: pointer;
    border-radius: 3em;
}

